import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { usePrevious } from "react-use";
import { formatAccountName, pluralize, zIndex } from "@puzzle/utils";
import {
  Dialog,
  Tabs,
  AutocompleteMenu,
  MenuButton,
  Text,
  Button,
  useToasts,
  Stack,
} from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { manualAccountIdVar } from "components/integrations/setup/modals/ConnectManualAccountFormStep";
import { ImportType, useIsImporting } from "components/common/import/useIsImporting";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import Analytics from "lib/analytics/analytics";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  GetTransactionsDocument,
  useCreateManualTransactionsMutation,
  useDirectIngestTransactionsMutation,
} from "../graphql.generated";
import { useSearchOptions } from "../hooks/useSearchOptions";
import { CSVImport } from "./CSVImport";
import { ManualImportForm } from "./ManualImport/ManualImportForm";
import { useManualImportForm, ManualImportFormValues } from "./ManualImport/useManualImportForm";
import { ImportState, ParsedCSVRow, useImportState } from "./useImportState";
import { AccountType } from "../../../../graphql/types";

export const Link = styled("a", { color: "$gray300 !important", textDecoration: "underline" });
const AccountRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$2h",
  alignItems: "end",
});

type ImportTransactionsModalProps = React.ComponentProps<typeof Dialog> & {
  initialAccountId?: ImportState["selectedAccountId"];
  defaultToManual?: boolean;
  refetch?: (added: { id: string; date: string; accountId: string }[]) => void;
};

const TABS = [
  {
    value: "csv",
    title: "Upload .csv file",
  },
  { value: "manual", title: "Add individually" },
];

export const ImportTransactionsModal = ({
  open,
  onOpenChange,
  initialAccountId,
  defaultToManual,
  refetch,
}: ImportTransactionsModalProps) => {
  const client = useApolloClient();
  const [activeTab, setActiveTab] = useState<string>(
    defaultToManual ? TABS[1].value : TABS[0].value
  );
  const { startImportProgress, endImportProgress, isImporting } = useIsImporting(
    ImportType.Transaction
  );
  const { updateImportState, reset, selectedAccountId, parsedCSV, selectedFile, uploadedFilePath } =
    useImportState();
  const previousInitialAccountId = usePrevious(initialAccountId);
  const { form } = useManualImportForm();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [createManualTransactionsMutation, { loading: manualTransactionsLoading }] =
    useCreateManualTransactionsMutation({ refetchQueries: [GetTransactionsDocument] });
  const [ingestTransactionsMutation] = useDirectIngestTransactionsMutation();

  useEffect(() => {
    // they've switched accounts, reset the import state so that the other account isn't the default
    // it seems like state for this hook is being preserved across switching reconciliations, so we need to do a
    // reset when switching between accounts
    if (previousInitialAccountId !== initialAccountId) {
      reset();
    }
  }, [previousInitialAccountId, reset, initialAccountId]);

  useEffect(() => {
    if (initialAccountId && !selectedAccountId) {
      updateImportState({ selectedAccountId: initialAccountId });
    }
  }, [initialAccountId, updateImportState, selectedAccountId]);

  const { institutions: allInstitutions } = useSearchOptions();

  const accountOptions = useMemo(() => {
    return (
      allInstitutions
        ?.flatMap((institution) =>
          institution.accounts.map((account) => ({
            ...account,
            institution,
          }))
        )
        .filter((account) => account.type !== AccountType.Payroll) || []
    );
  }, [allInstitutions]);

  const selectedAccount = useMemo(
    () =>
      selectedAccountId
        ? accountOptions.find(({ id }) => id === selectedAccountId)
        : initialAccountId
          ? accountOptions.find(({ id }) => id === initialAccountId)
          : null,
    [selectedAccountId, accountOptions, initialAccountId]
  );

  const disablePost = useMemo(
    () =>
      //check account id
      !selectedAccountId ||
      //check validity of csv/manual import
      (activeTab === "csv" ? !parsedCSV?.meta.isValid : !form.formState.isValid) ||
      //check if import loading
      isImporting ||
      manualTransactionsLoading,
    [
      selectedAccountId,
      activeTab,
      parsedCSV,
      form.formState,
      isImporting,
      manualTransactionsLoading,
    ]
  );

  const dialogWidth = activeTab === "manual" ? 948 : 648;

  const resetData = useCallback(() => {
    setTimeout(() => {
      reset();
    }, 100);
  }, [reset]);

  const handleClose = useCallback(() => {
    onOpenChange?.(false);
    form.reset();
    setActiveTab("csv");
  }, [form, onOpenChange]);

  const handleError = useCallback(() => {
    handleClose();
    endImportProgress();
    toast({
      title: `Importing transactions failed`,
      message: `Something went wrong. We recommend importing a maximum of 150 transactions at a time; we apologize for the inconvenience. If the problem persists, contact support.`,
      status: "error",
      duration: 10000,
    });
  }, [toast, handleClose, endImportProgress]);

  const handleTimeout = useCallback(() => {
    endImportProgress();
    handleClose();
    toast({
      title: `Still importing transactions`,
      message: `The import process is taking longer than expected - check back in a few minutes to see whether your transactions have been added.`,
      status: "warning",
      duration: 10000,
    });
  }, [toast, endImportProgress, handleClose]);

  const onImportCSV = useCallback(() => {
    if (!uploadedFilePath || !selectedFile || !parsedCSV) {
      return;
    }

    return ingestTransactionsMutation({
      variables: {
        input: {
          accountId: selectedAccountId ?? "",
          companyId: company.id,
          filename: selectedFile.name ?? "",
          contentType: selectedFile.type,
          path: uploadedFilePath ?? "",
          size: selectedFile.size,
          transactions: parsedCSV.data as Required<ParsedCSVRow>[],
        },
      },

      onError: (err) => {
        const trackingParams = {
          fileName: selectedFile.name ?? "",
          fileSize: selectedFile.size,
          contentType: selectedFile.type,
          totalRows: parsedCSV?.data.length ?? 0,
        };

        // @ts-expect-error will receive a 524 status code if request times out
        // gateway will still ingest transactions
        if (err?.networkError?.statusCode === 524) {
          handleTimeout();
          Analytics.transactionImportTimedOut(trackingParams);
          return;
        }

        // Generic graphql error
        handleError();
        Analytics.transactionImportFailed(trackingParams);
      },

      onCompleted({ directIngestTransactions }) {
        client.refetchQueries({
          include: [GetTransactionsDocument],
        });
        endImportProgress();
        reset();
        toast({
          title: `${pluralize(directIngestTransactions.savedCount, "transaction")} imported`,
          message: "Your imported transactions have been successfully added.",
          status: "success",
        });
        Analytics.transactionImportSucceeded({
          id: directIngestTransactions.id,
          fileName: selectedFile.name ?? "",
          fileSize: selectedFile.size,
          contentType: selectedFile.type,
          totalRows: directIngestTransactions.savedCount,
        });
      },
    });
  }, [
    client,
    uploadedFilePath,
    endImportProgress,
    handleError,
    handleTimeout,
    company.id,
    ingestTransactionsMutation,
    parsedCSV,
    reset,
    selectedAccountId,
    toast,
    selectedFile,
  ]);

  const onPostManualTransaction = useCallback(
    async (data: ManualImportFormValues) => {
      return await createManualTransactionsMutation({
        variables: {
          input: {
            companyId: company.id,
            accountId: selectedAccountId,
            transactions: data.items.map((item) => ({
              date: item.date.toString(),
              amount: item.amount.toString(),
              descriptor: item.description,
              vendorName: "",
              ledgerCoaKey: item.category?.coaKey ?? "no_category",
            })),
          },
        },
        onCompleted() {
          Analytics.manualTransactionsCreated({ totalRows: data.items.length });
        },
        onError() {
          Analytics.manualTransactionsFailed();
        },
      });
    },
    [company.id, createManualTransactionsMutation, selectedAccountId]
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          resetData();
          manualAccountIdVar(undefined);
        }
        onOpenChange?.(open);
      }}
      size="medium"
      width={dialogWidth}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
    >
      <Dialog.Title>Add transactions</Dialog.Title>
      <Dialog.Body>
        <Text css={{ display: "flex", color: "$gray200", paddingBottom: "$0h" }}>
          Post to account
        </Text>

        <Stack gap="2">
          <AccountRow>
            <AutocompleteMenu<(typeof accountOptions)[number], false, false, false>
              getOptionLabel={(option) => formatAccountName(option)}
              getOptionKey={(option) => option.id}
              groupBy={(option) => option.institution.name}
              options={accountOptions}
              value={selectedAccount ? selectedAccount : null}
              onChange={(e, newValue) => {
                updateImportState({
                  selectedAccountId: newValue ? newValue.id : undefined,
                });
              }}
              trigger={
                <MenuButton size="small" css={{ width: "196px", "> div": { width: "100%" } }}>
                  {selectedAccount ? (
                    formatAccountName(selectedAccount)
                  ) : (
                    <Text color="gray600">Select</Text>
                  )}
                </MenuButton>
              }
              css={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modalMenu") : "auto",
              }}
            />
            {activeTab === "csv" && (
              <Text color="gray300">
                <Link
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/1WnrmC38trBflseJ9ZjiNiKSI76lmy5JyDn3B7Z9DLDo/copy"
                  onClick={() => Analytics.transactionImportTemplateLinkClicked()}
                >
                  Download template
                </Link>{" "}
                with the required format.{" "}
                <Link
                  target="_blank"
                  href="https://puzzlefin.notion.site/Importing-bank-and-credit-card-transactions-5c86c97b11744afbb4d8b32121e90386"
                  onClick={() => Analytics.transactionImportHelpLinkClicked()}
                >
                  Learn more
                </Link>
              </Text>
            )}
          </AccountRow>

          <Tabs
            variant="minimal"
            items={TABS}
            value={activeTab}
            onValueChange={setActiveTab}
            css={{ borderBottom: "1px solid $mauve600" }}
          />
          {activeTab === "csv" && <CSVImport closeModal={() => handleClose()} />}
          {activeTab === "manual" && <ManualImportForm form={form} />}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={async () => {
              const transactions: { id: string; date: string; accountId: string }[] = [];
              if (activeTab === "csv") {
                startImportProgress();
                await onImportCSV();
              } else {
                const result = await onPostManualTransaction({ items: form.getValues("items") });
                for (const item of result.data?.createManualTransactions.transactions ?? []) {
                  transactions.push({ id: item.id, date: item.date, accountId: item.account.id });
                }
              }
              refetch?.(transactions);
              handleClose();
            }}
            disabled={disablePost}
          >
            Post transactions
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
