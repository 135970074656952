import keyBy from "lodash/keyBy";
import values from "lodash/values";
import { useMemo } from "react";
import { AccountType } from "graphql/types";
import { getFetchPolicyForKey, ONE_MINUTE } from "apollo/getFetchPolicyForKey";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCategories } from "components/common/hooks/useCategories";
import { useGetTransactionSearchOptionsQuery } from "../graphql.generated";

export function useSearchOptions() {
  const { company } = useActiveCompany<true>();
  const companyId = company.id;
  const {
    categories,
    categoriesByPermaKey,
    commonCategories,
    uncategorizedPermaKeys,
    loading: categoriesLoading,
  } = useCategories();
  const {
    data: searchOptions,
    loading: searchOptionsLoading,
    previousData,
    refetch,
  } = useGetTransactionSearchOptionsQuery({
    fetchPolicy: getFetchPolicyForKey(`${companyId}.useSearchOptions`, ONE_MINUTE * 10),
    context: { batch: false },
    variables: { companyId },
  });

  // Filter out payroll accounts
  const filteredAccounts = useMemo(() => {
    const allInstitutions = searchOptions?.financialInstitutionsFor || [];
    return allInstitutions
      .flatMap((f) => f.accounts)
      .filter((acc) => acc.type !== AccountType.Payroll);
  }, [searchOptions?.financialInstitutionsFor]);

  // All the unique institutions from filtered accounts
  const institutions = useMemo(() => {
    const allInstitutions = searchOptions?.financialInstitutionsFor || [];
    const filteredInstitutionIds = new Set(
      filteredAccounts.map((acc) => acc.financialInstitution.id)
    );
    return allInstitutions.filter((f) => filteredInstitutionIds.has(f.id));
  }, [searchOptions?.financialInstitutionsFor, filteredAccounts]);

  const accountsById = useMemo(() => {
    return keyBy(filteredAccounts, "id");
  }, [filteredAccounts]);

  const creditCardAccounts = useMemo(() => {
    return values(accountsById).filter((acc) => acc.type === AccountType.Credit);
  }, [accountsById]);
  const cards = useMemo(() => searchOptions?.cards.items || [], [searchOptions?.cards]);
  const cardsById = useMemo(() => keyBy(cards, "id"), [cards]);

  return {
    categoriesByPermaKey,
    categories,
    commonCategories,
    uncategorizedPermaKeys,
    creditCardAccounts,
    cards,

    // TODO Prefer readFragment
    cardsById,
    accountsById,

    institutions,

    // Ignore vendor loading state until we prune it
    // If there is previous data and the cache is expired, we don't need a loading state.
    loading: previousData ? false : categoriesLoading || searchOptionsLoading,
    refetch,
  };
}
