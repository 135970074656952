import { DateRangeFormatPart, DateValue } from "@internationalized/date";
import { format as formatDate } from "date-fns-tz";
import { useMemo, useCallback } from "react";
import { DateFormatterOptions, useDateFormatter } from "@react-aria/i18n";

export type DateFormatterResult = {
  /** Formats a date as a string according to the locale and format options passed to the constructor. */
  format(value: Date | DateValue): string;
  /** Formats a date to an array of parts such as separators, numbers, punctuation, and more. */
  formatToParts(value: Date | DateValue): Intl.DateTimeFormatPart[];
  /** Formats a date range as a string. */
  formatRange(start: Date | DateValue, end: Date | DateValue): string;
  /** Formats a date range as an array of parts. */
  formatRangeToParts(start: Date | DateValue, end: Date | DateValue): DateRangeFormatPart[];
  /**
   * Formats using a custom format using date-fns-tz
   * https://date-fns.org/docs/format
   */
  customFormat(value: Date | DateValue, format: string): string;
  /** Returns the resolved formatting options based on the values passed to the constructor. */
  resolvedOptions(): Intl.ResolvedDateTimeFormatOptions;
};

export const useNormalizedDateFormatter = (options?: DateFormatterOptions): DateFormatterResult => {
  const timeZone = options?.timeZone;
  const normalizeDate = useCallback(
    (date: Date | DateValue): Date =>
      date instanceof Date
        ? date
        : // The library doesn't actually seem to care if timeZone is missing (which is necessary when normalizing months across different DST)
          date.toDate(timeZone!),
    [timeZone]
  );
  const result = useDateFormatter(options);

  return useMemo(
    () => ({
      format(value) {
        return result.format(normalizeDate(value));
      },

      formatToParts(value) {
        return result.formatToParts(normalizeDate(value));
      },

      formatRange(start, end) {
        return result.formatRange(normalizeDate(start), normalizeDate(end));
      },

      formatRangeToParts(start, end) {
        return result.formatRangeToParts(normalizeDate(start), normalizeDate(end));
      },

      customFormat(value, format) {
        return formatDate(normalizeDate(value), format, { timeZone });
      },

      resolvedOptions() {
        return result.resolvedOptions();
      },
    }),
    [result, timeZone, normalizeDate]
  );
};

/**
 * Creates an Intl.DateTimeFormat instance using the user's time zone.
 * Compatible with DateValues.
 *
 * @param {DateFormatterOptions} options
 * @returns {DateFormatterResult} formatter
 */
export function useLocalDateFormatter(options?: DateFormatterOptions): DateFormatterResult {
  return useNormalizedDateFormatter(options);
}
