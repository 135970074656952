import { format as formatDate } from "date-fns-tz";
import { isMatch } from "date-fns/isMatch";
import { parse } from "date-fns/parse";
import * as yup from "yup";

function attemptDateNormalization(date: string) {
  const formats = ["M/d/yy", "M/d/yyyy", "yyyy-M-d", "yyyy-MM-dd"];

  for (const format of formats) {
    if (isMatch(date, format)) {
      const parsedDate = parse(date, format, new Date());
      const normalizedDate = formatDate(parsedDate, "yyyy-MM-dd");
      return normalizedDate;
    }
  }

  return date;
}

const sanitize = (s?: string) => (s ? s.replace("$", "").replace("-", "").replace(",", "") : null);

// Guarantee it's an integer or has up to 2 decimals, with an optional negative sign
const integerRegex = /^-?\d+(\.\d{1,2})?$/;

export const schemaValidators = {
  date: yup
    .string()
    .transform(attemptDateNormalization)
    // After casting, make sure it's a valid date
    .test("YYYY-MM-DD", (x) => Boolean(x && isMatch(x, "yyyy-MM-dd"))),
  positiveOnlyAmount: yup.string().transform(sanitize).matches(integerRegex),
  amount: yup.string().matches(integerRegex),
};
