import React, { useCallback } from "react";
import { Button } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Add } from "@puzzle/icons";
import { ManualImportFormValues } from "./useManualImportForm";
import { useCategories } from "components/common/hooks/useCategories";
import { ManualImportRow } from "./ManualImportRow";

const AddRow = styled("tr", {
  td: {
    padding: "$1 0",
    position: "relative",
  },
});

const Table = styled("table", {
  maxWidth: "100%",
  tableLayout: "fixed",
  borderCollapse: "collapse",

  tr: {
    "&:not(:first-child):not(:last-child) ": {
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
    },
  },

  "th, td": {
    textAlign: "left",
    color: "$gray200",
    textVariant: "$bodyS",
  },

  th: {
    color: "$gray200",
    paddingLeft: "$1",
  },

  td: {
    padding: "$1h $0h",
    height: "58px",
    borderColor: "$gray600",
  },
});

export const ManualImportForm = ({ form }: { form: UseFormReturn<ManualImportFormValues> }) => {
  const { categories, categoriesByPermaKey } = useCategories();
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: "items",
  });
  const items = form.watch("items", fields);

  const addRow = useCallback(
    (copyIndex: number) => {
      append({
        description: items[copyIndex]?.description ?? "",
        amount: items[copyIndex]?.amount ?? "0",
        date: items[copyIndex]?.date ?? items[0].date,
        category: items[copyIndex]?.category ?? undefined,
      });
    },
    [append, items]
  );

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: 140 }}>Date</th>
          <th style={{ width: 140 }}>Amount</th>
          <th style={{ width: 300 }}>Description</th>
          <th style={{ width: 260 }}>Category</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => (
          <ManualImportRow
            index={index}
            key={field.id}
            onRemove={fields.length > 1 ? () => remove(index) : undefined}
            onDuplicate={() => addRow(index)}
            form={form}
            categories={categories}
            categoriesByPermaKey={categoriesByPermaKey}
          />
        ))}

        <AddRow>
          <td>
            <Button prefix={<Add />} variant="minimal" size="small" onClick={() => addRow(-1)}>
              Add more rows
            </Button>
          </td>
        </AddRow>
      </tbody>
    </Table>
  );
};
